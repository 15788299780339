const validationMessage = $("#validation-message")
const requiredFields = $(".required");
const form = $('form')
const gradesTaught = $('#grades-taught')
const charterSplit = $('#charters-split')
const scheduledDays = $('#scheduled_days')

function form_errors() {
    let errors = ''
    if (!validate_charter_percentage()) {
        errors += ' Charter percentages must add to 100'
    }
    if (!validate_days_of_week()) {
        errors += ' Please select scheduled days'
    }
    if (!validate_grades_taught()) {
        errors += ' Please select grades taught'
    }
    if (required_field_errors() !== '') {
        errors += ' Please fill in required fields'
    }
    return errors
}
function required_field_errors() {
    let errors = ''
    requiredFields.each(
        function () {
            if (invalid_field($(this)) && $(this).is(":visible")) {
                errors = ' Cannot Be Blank!';
            }
        }
    );
    return errors;
}

function invalid_field(field) {
    let error = null;
    if (field.val() === '') {
        error = 'Cannot be Blank';
    }
    if (error) {
        add_error(field, error)
    } else {
        clear_error(field)
    }
    return error;
}

function add_error(field, error) {
    const error_field_id = field.attr('name') + '_error'
    field.addClass("error");
    field.css("background-color", "#f2dede");
    field.css("color", '#8B0000');
    const error_html = '<p id="' + error_field_id + '" style="color:#8B0000;" >' + error + '</p>'
    field.after(error_html)
}

function clear_error(field) {
    const error_field_id = field.attr('name') + '_error'
    field.removeClass("error");
    field.css("background-color", "#ffffff");
    field.css("color", "black");
    $('#' + error_field_id).hide()
}

function submit_form() {
    const errors = form_errors()
    if (errors === '') {
        validationMessage.hide();
        form.submit();
    } else {
        validationMessage.text('There are errors preventing this form from being saved.' + errors)
        validationMessage.show();
    }
}
function validate_charter_percentage() {
    if (!charterSplit.is(":visible")) {
        return true
    }
    const percentages = $('.charterSplit').map(function () {
        return this.value;
    }).get()
    const total = percentages.reduce((prev, curr) => prev + parseInt(curr), 0);
    return total === 100
}

function validate_days_of_week() {
    if (!scheduledDays.is(":visible")) {
        return true
    }
    const checkboxes = Array.from($(`[id^="_scheduled_days"]`))
    return checkboxes.some(checkbox => checkbox.checked)
}

function validate_grades_taught() {
    if (!gradesTaught.is(":visible")) {
        return true
    }
    const checkboxes = Array.from($(`[id^="_grades_taught"]`))
    return checkboxes.some(checkbox => checkbox.checked)
}

export {submit_form, clear_error, add_error};